<!--
 * @Desc: 描述
 * @Date: 2020-02-14 22:24:20
 * @LastEditTime: 2024-08-25 23:39:13
 -->
<template>
  <div class="step-1">
    <!-- <div style="color: #333; margin-bottom: 5px">上传范例：（仅支持jpg、jpeg、png）</div>
    <el-image :src="demo" :preview-src-list="[demo]"></el-image> -->
    <div class="account-box">
      <div style="margin-right: 10px">
        <div style="border-bottom: 1px solid #eee">
          <p style=" font-weight: bold">报项汇款方式1：汇款至银行账号</p>
          <p><span>账号名称</span> 佛山市禅城区武术协会</p>
          <p><span>开户行</span> 佛山农村商业银行股份有限公司营业部</p>
          <p><span>账 号</span> 8002 0000 0063 47573</p>
        </div>
        <!-- <div style="border-bottom: 1px solid #eee">
          <p style=" font-weight: bold">报项汇款方式2：汇款至微信</p>
          <p><img :src="qrcodeImg" width="100px" /></p>
        </div> -->
        <p>
          <span>注意：</span>请各参赛队伍转账时须<span>备注队伍名称</span>，
        </p>
        <p>
          汇款后请将汇款回执或凭据在报名系统上传。
        </p>
      </div>
      <el-upload
        :action="
          $store.state.user.authority == 1
            ? `${rootUrl}/admin/upload/prove`
            : `${rootUrl}/api/upload/prove`
        "
        list-type="picture-card"
        :data="{ uid, token: $store.state.user.token }"
        :on-preview="handlePictureCardPreview"
        :before-remove="handleRemove"
        :on-error="handleError"
        :on-success="handleSuccess"
        :with-credentials="true"
        :before-upload="beforeUpload"
        class="upload-wrap"
        accept="image/png, image/jpeg"
        :headers="myHeaders"
        :file-list="fileList"
        :drag="true"
      >
        <i class="el-icon-plus" />
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </div>

    <!-- <div class="account-box">
      <div>
        <p style=" font-weight: bold">初段位技术考评及申报——汇款账号</p>
        <p><span>账号名称</span> 佛山市三水区武术龙狮运动协会</p>
        <p><span>开户行</span> 佛山农村商业银行三水支行</p>
        <p><span>账 号</span> 80020000005683152</p>
        <p>
          <span>注意1：</span
          >请各参赛队伍转账时须<span>备注队伍名称</span>，汇款后请于 4 月 20 日
          24:00
          前（报名截止时间），将汇款回执或凭据复印件、段前级、段位考评申报表于 4
          月 20 日前统一发送邮箱479288556@qq.com。
        </p>
        <p>
          <span>注意2：</span>初段位技术考评及申报的汇款凭证无需上传至本报名系统
        </p>
        
        <p><span>段位咨询联系人</span>:陈兆:17328082485</p>
      </div>
    </div> -->

    <div class="step-btn-group">
      <el-button type="primary" @click="prevStep()">上一步</el-button>
      <el-button
        type="primary"
        @click="nextStep()"
        v-if="$store.state.user.authority === 0"
        >下一步</el-button
      >
    </div>
  </div>
</template>

<script>
import { getToken, getCookie } from "@/utils/auth";
import demo from "@/assets/demo-prove.jpg";
import qrcodeImg from "@/assets/money-qrcode-img.jpg";
import { getProve, getProveAdmin, delProveAdmin, delProve } from "@/api";
// 引入image-conversion
import * as imageConversion from "image-conversion";
import { BASE_URL } from "@/const";

export default {
  name: "Step1",
  components: {},
  props: {},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      rootUrl: BASE_URL,
      demo,
      qrcodeImg
    };
  },
  computed: {
    myHeaders() {
      return {
        "X-AccountType": getToken(),
        "X-Token": getCookie("authority"),
        "X-Author": this.$store.getters.author
      };
    },
    uid() {
      return this.$store.state.user.authority === 1
        ? this.$route.query.id || this.$store.state.user.id
        : this.$store.state.user.id;
    }
  },
  watch: {},
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      const request =
        this.$store.state.user.authority === 0 ? getProve : getProveAdmin;

      request()
        .then(res => {
          let data = res.data.list.length > 0 ? res.data.list.split(",") : [];
          let file = [];
          data.forEach(item => {
            file.push({
              url: item
            });
          });
          this.fileList = file;
          this.list = file;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err
          });
        });
    },
    handleRemove(file, fileList) {
      return new Promise((resolve, reject) => {
        const request =
          this.$store.state.user.authority === 0 ? delProve : delProveAdmin;

        request()
          .then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success"
              });
              this.visible = false;
              this.getList();
              resolve();
            } else {
              this.$message.error(res.data.message || "删除失败");
              reject();
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message.error("删除失败");
            reject();
          });
      });
    },
    handleError(err, file, fileList) {
      this.$message({
        type: "error",
        message: err
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.success("上传成功");
      this.list.push({
        uid: file.uid,
        url: file.url
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    prevStep() {
      this.$store.dispatch("STEP_PREV", {
        router: this.$router,
        route: this.$route
      });
    },
    nextStep() {
      if (this.list.length > 0) {
        this.$store.dispatch("STEP_NEXT", {
          router: this.$router,
          route: this.$route
        });
      } else {
        this.$alert("请上传汇款证明", "系统提示", {
          confirmButtonText: "确定"
        });
      }
    },
    //图片大小超过1M,长度超过2000就压缩
    beforeUpload(file) {
      // 图片不大于1.5m,宽度不大于2000
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL;
        let isLt1M = file.size / 1024 / 1024 > 1; // 判定图片大小是否小于1MB
        // 这里需要计算出图片的长宽
        let img = new Image();
        img.onload = function() {
          file.width = img.width; // 获取到width放在了file属性上
          file.height = img.height; // 获取到height放在了file属性上
          let valid = img.width > 2000; // 图片宽度大于2000
          // console.log(11, file)
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          if (valid || isLt1M) {
            imageConversion
              .compressAccurately(file, {
                size: 280,
                width: 2000
              })
              .then(res => {
                // console.log('res', res) // 压缩到400KB,这里的400就是要压缩的大小,可自定义
                resolve(res);
              });
          } else {
            resolve(file);
          }
        }; // 需要把图片赋值
        img.src = _URL.createObjectURL(file);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-image {
  width: 150px;
  height: 150px;
  border: 1px solid #c0ccda;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}
.upload-wrap {
  border: 1px solid rgb(133, 132, 132);
  border-radius: 5px;
  padding: 20px 20px;
}
.btn-group {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.account-box {
  display: flex;
  color: #333;
  margin: 0px 0 15px 15px;
  padding: 15px 0 0;
  border-top: 1px solid #5e5e5e;
  span {
    font-weight: bold;
  }
  > div {
    flex: 1;
  }
}

@media screen and (max-width: 991px) {
  .account-box {
    display: block;
  }
}
</style>

<style>
.el-upload-dragger {
  width: 100%;
  height: 100%;
}
</style>
